module.exports = [{
      plugin: require('/home/jsingh/code/jaz/sbentleyart.com-2.0.0/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('/home/jsingh/code/jaz/sbentleyart.com-2.0.0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-146463563-1"},
    },{
      plugin: require('/home/jsingh/code/jaz/sbentleyart.com-2.0.0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

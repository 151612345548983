// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-art-piece-js": () => import("/home/jsingh/code/jaz/sbentleyart.com-2.0.0/src/templates/art-piece.js" /* webpackChunkName: "component---src-templates-art-piece-js" */),
  "component---src-templates-gallery-js": () => import("/home/jsingh/code/jaz/sbentleyart.com-2.0.0/src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-pages-404-js": () => import("/home/jsingh/code/jaz/sbentleyart.com-2.0.0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galleries-js": () => import("/home/jsingh/code/jaz/sbentleyart.com-2.0.0/src/pages/galleries.js" /* webpackChunkName: "component---src-pages-galleries-js" */),
  "component---src-pages-index-js": () => import("/home/jsingh/code/jaz/sbentleyart.com-2.0.0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

